<p-button
  [class.centered-text]="centeredText"
  [label]="label"
  [outlined]="true"
  [severity]="severity"
  [disabled]="disabled"
  [type]="type"
  [icon]="icon"
  [styleClass]="class"
  (click)="buttonClicked.emit()"
  [rounded]="rounded"
/>
